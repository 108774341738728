import React from 'react';

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';

import { Link } from 'gatsby';

/**
 * Displays a call to action to sign up in this app, for unauthenticated users
 * @param {*} props 
 * @author BrianCastor
 */
export const SignupCallToAction = props => {
    let serviceName = props.serviceName

    if (!serviceName) {
        serviceName = 'this service'
    }

    return (
        <Card style={{ marginBottom: '15px' }}>
            <CardContent style={{ textAlign: 'center' }}>
                <Typography gutterBottom variant="h6" component="h3">
                    Find more services like {serviceName}
                </Typography>
                <Button color="primary" variant="contained" component={Link} to="/signup">Discover services</Button>
            </CardContent>
        </Card>
    );
}