import React from 'react';
import RoomIcon from '@material-ui/icons/Room';

/**
 * Displays a formatted address
 * @param {*} props 
 * @author BrianCastor
 */
export const AddressDisplay = props => {
    const address = props.address;
    let addressText = '';

    //If it has no data, display a dash
    if (!address || Object.keys(address).length === 0) {
        addressText = '-'
    }
    else {
        addressText = [address.street, address.city, address.state].filter(val => val !== null).join(', ')
        addressText = [addressText, address.postalCode].filter(val => val !== null).join(' ')
        addressText = [addressText, address.country].filter(val => val !== null).join(', ')
    }

    return (
        <div style={{paddingTop:'0px', paddingBottom:'10px', display:'flex'}}>
        <RoomIcon style={{verticalAlign:'middle', fontSize:'17px', paddingRight:'5px'}}/>
        <div>{addressText}</div>
        </div>
    )
}