import React from 'react';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { ServiceIcon } from './serviceIcon';

/**
 * Displays a list of services + icons, with further information about them (available, free or discounted, etc)
 * @param {*} props 
 * @author BrianCastor
 */
export const ServicesDisplayV2 = (props) => {
    if (!props.services) {
        return null;
    }
    if (props.services.length === 0) {
        return (<span>No services specified</span>);
    }
    else {
        return (
            <div>
                {props.services.map((service, index) => {
                    /*Service Categories (food, shelter, etc) - only show ones marked as True*/
                    return (
                        <div key={index} style={{display:'flex'}}>
                            <div style={{marginRight: '10px', marginBottom: '8px' }}>
                                <ServiceIcon service={service.Service_Category__c}/>
                            </div >
                            <div style={{ marginBottom: '8px'}}>
                                <strong>{service.Service_Category__c}</strong>
                                <div>
                                <div style={{display:'inline-block', marginRight:'10px'}}>
                                {service.Is_it_available__c ? 
                                (<CheckIcon style={{ color: 'green', fontSize: '13px', padding:'0px 5px'}} />)
                                :
                                (<CloseIcon style={{ color: 'red', fontSize: '13px', padding:'0px 5px'}} />)}
                                 Available
                                </div>
                                <div style={{display:'inline-block', marginRight:'10px'}}>
                                {service.Is_it_free__c ? 
                                (<span><CheckIcon style={{ color: 'green', fontSize: '13px', padding:'0px 5px'}} />Free</span>)
                                :
                                (null)}
                                </div>
                                <div style={{display:'inline-block', marginRight:'10px'}}>
                                {service.Is_it_discounted__c ? 
                                (<span><CheckIcon style={{ color: 'green', fontSize: '13px', padding:'0px 5px'}} />Discounted</span>)
                                :
                                (null)}
                                </div>
                                <div style={{display:'inline-block', marginRight:'10px'}}>
                                {service.Does_it_require_a_referral__c ? 
                                (<span><WarningIcon style={{ color: '#e0b602', fontSize: '13px', padding:'0px 5px'}} />Requires Referral</span>)
                                :
                                (null)}
                                 </div>
                                 </div>
                            </div>
                        </div>
                    )
                }
                )}
            </div>
        )
    };
}