import React from 'react';
import PhoneOutlined from '@material-ui/icons/PhoneOutlined';

/**
 * Displays a phone number in a formatted manner
 * @param {*} props 
 * @author BrianCastor
 */
export const PhoneDisplay = props => {
    let elem = null;
    
    //If we don't have a number, display 'None provided'
    if (!props.number) {
        elem = (<span>-</span>)
    }
    
    //Otherwise display a link that calls the number when you click it
    else {
        elem = <a href={'tel:'+props.number}>{props.number}</a>
    }
    return (
        <div style={{padding:'5px 0px', display:'flex'}}>
            <PhoneOutlined style={{verticalAlign:'middle', fontSize:'17px', paddingRight:'5px'}}/>
            <div>
                {props.description ? <strong>{`${props.description}: `}</strong> : ('')}
                <span>{elem}</span>
            </div>
        </div>
    );
}