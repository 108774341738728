import React from 'react';
import PortableWifiOffIcon from '@material-ui/icons/PortableWifiOff';

/**
 * Generic error component to display when we hit an error
 * @param {*} props 
 * @author BrianCastor
 */
export const ErrorDisplay = props => {
    let error = props.error;
    if (!props.error) {
        error = "Sorry, we've run into an error fetching data. Please check your internet connection, refresh your page, and try again.";
    }
    error = error.toString();
    return (
        <div style={{textAlign:'center', marginTop:'100px'}}>
            <PortableWifiOffIcon style={{ fontSize: 100, color:'pink' }}/>
            <p>{error}</p>
        </div>
    );
}