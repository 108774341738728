import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PostingDetail from "../components/postings/postingDetail"
import Container from "@material-ui/core/Container";

import { useLocation } from '@reach/router';

const PostingDetailPage = () => {
  let path = useLocation();
  let postingId = path.search.split('?id=')[1];

  return (
    <Layout>
      <Seo title="Posting" />
      <div style={{ paddingBottom: '100px' }}>
        <Container maxWidth="sm" style={{ paddingTop: '10px' }}>
          <PostingDetail id={postingId} />
        </Container>
      </div>
    </Layout>
  )
}

export default PostingDetailPage
