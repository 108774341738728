import React from 'react';
import Chip from "@material-ui/core/Chip";

/**
 * Displays a list of Demographic/Community 'chips' based off of a list of terms passed in
 * @param {*} props 
 * @author BrianCastor
 */
export const DemographicsDisplay = props => {
    return (
        <div>
            {props.Demographics.map((demographic, index) => {
                /*Demographics/Communities - only show ones marked as True*/
                if (demographic) {
                    return <Chip
                        label={demographic}
                        color="primary"
                        key={index}
                        variant="outlined"
                        size="small"
                        style={{ margin: '2px' }}
                    />
                }
                else {
                    return ''
                }
            })}
        </div>
    );
}