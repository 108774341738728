import React from 'react';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import {getDistance} from "../../utils";

/**
 * Displays a distance in miles (as the crow flies) between the currentLocation prop and the destination prop
 * @param {*} props 
 * @author BrianCastor
 */
export const DistanceDisplay = props => {
    let distanceText = '';
    if (!props.destination) {
        distanceText = 'No location given';
    }
    else if (!props.currentLocation) {
        distanceText = 'Enter your location on your profile to view distance'
    }
    else {
        let distanceInMiles = getDistance(props.currentLocation, props.destination);
        if (distanceInMiles) {
            distanceText = `${distanceInMiles} miles away`;
        }
        else {
            distanceText = 'No location given';
        }
    }
    return (
        <div style={{padding:'5px 0px'}}>
            <LocationOnOutlinedIcon style={{verticalAlign:'middle', fontSize:'17px', paddingRight:'5px'}}/><span>{distanceText}</span>
        </div>
    );
}