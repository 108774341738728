import React from 'react';
import LanguageIcon from '@material-ui/icons/Language';

/**
 * Displays a clickable link to a website with an icon next to it
 * @param {*} props 
 * @author BrianCastor
 */
export const WebsiteDisplay = props => {
    const displayUrl = props.url;
    let url = props.url;

    //Accommodate both http(s) and urls without it
    if (url) {
        if (!url.toString().toLowerCase().startsWith('http')) {
            //URLs without http or https need to be prefixed with // in hrefs to correctly link
            url = '//' + url.toString();
        }
    }


    return (
        <div style={{padding:'5px 0px'}}>
            <LanguageIcon style={{verticalAlign:'middle', fontSize:'17px', paddingRight:'5px'}}/>
            {url ? (<a href={url} target="_blank" rel="noreferrer">{displayUrl}</a>) : (<span>-</span>)}
        </div>
    )
}